import React, { useState } from "react"

const FilterBar = ({ setFilters, properties }) => {  
  const [ state, setState ] = useState('All');
  const [ county, setCounty ] = useState('All');
  const [ acres, setAcres ] = useState('All');
  const availableStates = Array.from(new Set(properties.map(property => property.property_details.state)));
  const availableCounties = Array.from(new Set(properties.map(property => property.property_details.county)));

  const applyFilters = (evt) => {
    evt.preventDefault();
    setFilters({ state, county, acres });
  };

  return (
    <form className="mx-auto mt-8 md:mt-12 flex flex-col md:flex-row items-center md:items-end" onSubmit={applyFilters}>
      <div className="mr-0 md:mr-6">
        <h4 className="text-bold text-gray-800">Filter Properties by:</h4>
      </div>
      
      <div className="mt-1 md:mt-0 mr-0 md:mr-6 w-full md:w-1/5">
        <label htmlFor="state" className="block text-sm font-medium leading-5 text-gray-700">
          State
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select onBlur={(event) => setState(event.target.value)} id="state" className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option>All</option>
            {availableStates.map((st, index) => {
              return (
                <option key={index}>{st}</option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="mt-1 md:mt-0 mr-0 md:mr-6 w-full md:w-1/5">
        <label htmlFor="county" className="block text-sm font-medium leading-5 text-gray-700">
          County
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select onBlur={(event) => setCounty(event.target.value)} id="county" className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option>All</option>
            {availableCounties.map((cnty, index) => {
              return (
                <option key={index}>{cnty}</option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="mt-1 md:mt-0 mr-0 md:mr-6 w-full md:w-1/5">
        <label htmlFor="Acres" className="block text-sm font-medium leading-5 text-gray-700">
          Acres
        </label>
        <div className="mt-1 rounded-md shadow-sm">
          <select onBlur={(event) => setAcres(event.target.value)} id="acres" className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option>All</option>
            <option value={5}>{`0 - 5 Acres`}</option>
            <option value={20}>{`5 - 20 Acres`}</option>
            <option value={Infinity}>{`20+ Acres`}</option>
          </select>
        </div>
      </div>

      <div className="w-full md:w-32 mt-5 md:mt-0">
        <button type="submit" className="w-full inline-flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-indigo active:bg-green-700 transition duration-150 ease-in-out">
          <svg className="w-4 h-4 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="currentColor" viewBox="0 0 24 24">
            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
          Search
        </button>
      </div>
    </form>
  );
}

export default FilterBar