import React, { useState } from "react"
import { graphql } from "gatsby"
import { filterProperties, showSoldPropertiesLast } from "../../utils/properties"

import PropertyCard from "./components/property-card"
import FilterBar from "./components/filter-bar"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const PropertiesPage = ({ data }) => {    
  const { sanityPropertiesPage: page } = data;
  const properties = data.properties.nodes;
  const [ filters, setFilters ] = useState({ state: 'All', county: 'All', acres: 'All' });
  const filteredProperties = filterProperties(properties, filters);

  return (
    <Layout>
      <SEO title={page.seo_title} description={page.seo_description} />

      <div className="relative bg-gray-50 px-4 py-16 lg:py-24">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>

        <div className="relative container">
          <div className="text-center">
            <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Properties
            </h2>
            <p className="mt-3 max-w-2xl mx-auto text-xl md:text-center leading-7 text-gray-500 sm:mt-4">              
              Our full inventory of available properties. Looking for land of a certain size, or certain state? Use our filters to quickly find what you need!
            </p>
          </div>

          <FilterBar properties={properties} setFilters={setFilters} />

          <div className="mt-12 grid gap-8 max-w-lg mx-auto sm:grid-cols-2 sm:max-w-4xl lg:grid-cols-3 lg:max-w-none">
            {filteredProperties.length >= 1 
              ? showSoldPropertiesLast(filteredProperties).map((property) => {
                return <PropertyCard key={property.id} property={property} />;
              })
              : <h4 className="h-40 mt-12 col-span-3 flex justify-center text-xl text-gray-800">
                  We didn't find any properties that matched those criteria!
                </h4>
            }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PropertiesPage


export const query = graphql`
  query PropertiesPageQuery {
    sanityPropertiesPage {
      seo_title
      seo_description
      heading
      description
    }
    properties: allSanityProperty(filter: {hidden: {eq: false}}) {
      nodes {
        id
        slug {
          current
        }
        sold
        title
        acres
        property_details {
          state
          county
          cash_price
          monthly_payment
        }
        images {
          asset {
            fluid(maxWidth: 450) {
              ...GatsbySanityImageFluid
            }
          }
        }        
        _rawDescription
      }      
    }
  }
`